.page-row {
  display: flex;
}

.page-column {
  padding: 160px 40px 40px 40px;
}

.page-column.left {
  flex: 40%;
  position: sticky;
  align-self: flex-start;
  top: 0;
}

.page-column.left-content {
  flex: 40%;
}

.page-column.right {
  flex: 60%;
  right: 0;
}

/* Small Desktop */
@media (max-width: 1279px) {
  
  .page-row {
    display: block;
  }

  .page-column.right {
    padding-top: 40px;
  }

  .page-column.left {
    position: relative;
    display: block;
    width: 90%;
  }
  
}

/* Tablet & Mobile */
@media (max-width: 1024px) {
  
  .page-row {
    display: block;
  }

  .page-column {
    padding: 120px 0px 20px 0px;
    margin: 0 auto;
    width: 90%;
  }

  .page-column.right {
    padding-top: 20px;
  }

  .page-column.left {
    position: relative;
    display: block;
  }
  
}